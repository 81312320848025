@import '../../../theme/breakpoints.scss';

.product-container {
  background-color: white;
  border-top: solid 5px #fbb034;
  .card-title {
    color: #002c6c;
    width: 80%;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 24px;
    font-family: 'Gotham-ssm-light';
    font-weight: bold;
  }
  &.complete {
    border-top: solid 5px #7ac143;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  @media screen and (max-width: $sm) {
    .row {
      flex-direction: column;
    }
  }

  table {
    border: none;
    margin: 10px 0;
    margin-left: 0;
    tr {
      border-bottom: solid 1px #d8d8d8;
      td {
        color: #002c6c;
        font-size: 14px;
        line-height: 16px;
        padding: 5px;
        width: 100vh;
        span {
          color: #888b8d;
        }
      }
      td:nth-child(1) {
        color: #696d73;
        min-width: 20vh;
        width: 25vh;

        font-size: 1rem;

        padding: 10px 0;
      }
      td:nth-child(2) {
        color: #002c6c;
        font-family: 'GothamSSm-Book-Regular';
      }
    }
  }
}

.button {
  color: #008dbd;
}

.formItem {
  .ant-form-item {
    width: 100vw;
  }
  .ant-input-group-addon {
    padding: 0px 0px;
  }

  .textarea.ant-input:focus {
    border-color: #cd3c0d;
  }
  .ant-input-affix-wrapper {
    border: 1px solid #c4d7ed;
    border-right-width: 0px;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 10px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: #cd3c0d;
    border-color: #cd3c0d;
  }
}

.unknown {
  color: #888b8d;
}

.single-padding {
  padding-bottom: 20px;
  padding-top: 15px;
  .ant-row {
    flex-flow: row nowrap;
  }
}

.description-text {
  word-break: break-word;
  width: 100%;
}

.singleClassName {
  margin-bottom: 30px;
  font-size: large;
}

.breadcrumb-style {
  margin-bottom: 10px;
}

.warning-style {
  font-weight: bold;
  font-size: 20px;
}
.search-results {
  font-size: 30px;
  font-family: 'Gotham-ssm-light';
  color: #002c6c;
}

@media screen and (max-width: $sm) {
  .table-style {
    padding: 2vh;
  }
}

.image-container {
  padding: 1em;
  align-items: center;
  display: flex;
  height: 300px;
  width: 300px;
  .text-position {
    justify-content: center;
    display: flex;
  }

  img {
    width: 100%;
    object-fit: contain;
    height: 200px;
    align-self: center;
  }
}

.dots-style {
  li button {
    background-color: #002c6c !important;
  }
}

@media screen and (max-width: $lg) {
  .image-container {
    img {
      width: 100%;
      object-fit: contain;
      height: 30vh;
      justify-content: center;
    }
  }
}

.gtin-table-style {
  width: 100%;
  table {
    width: 100%;
  }
  td:nth-child(2) {
    width: 100%;
  }
}

@media screen and (max-width: $lg) {
  .gtin-table-style {
    width: 100%;
    padding-left: 1vh;
  }
}

@media screen and (max-width: $lg) {
  .single-padding {
    .ant-row {
      flex-flow: row wrap;
      justify-content: center;
      width: 100%;
    }
  }
}
